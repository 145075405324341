import { JsonProperty, Serializable } from "typescript-json-serializer"
import FilterData from "@/types/Product/FilterData"

@Serializable()
export default class ProductCategoryFilter {
  @JsonProperty() label!: string

  @JsonProperty() key!: string

  @JsonProperty({ type: FilterData }) data: Array<FilterData> = []
}
