






























































import { Component, PropSync, Watch } from "vue-property-decorator"
import ProductApi from "@/api/Product/ProductApi"
import ProductCategoryFilter from "@/types/Product/ProductCategoryFilter"
import { deserialize } from "typescript-json-serializer"
import _ from "lodash"
import { mixins } from "vue-class-component"
import BreakpointMixin from "@/types/BreakpointMixin"
import qs from "qs"

@Component({
  name: "ProductCategoryFilterDrawer"
})
export default class ProductCategoryFilterDrawer extends mixins(BreakpointMixin) {
  @PropSync("shade-color") syncedShadeColor!: string

  @PropSync("usability") syncedUsability!: string

  @PropSync("open", { default: false }) filterDrawer!: boolean

  private cols = 10

  private filterTemplate: ProductCategoryFilter[] = []

  private panel = []

  private filter = {}

  private oldGroups: number[] = []

  // eslint-disable-next-line class-methods-use-this
  changeKey(e: number[], key: string) {
    this.$emit("filtering")
    let checkAllGroup = false
    if (key === "product_group") {
      // @ts-ignore
      if (e.includes(-1) && !this.oldGroups.includes(-1)) checkAllGroup = true
      this.oldGroups = e
    }
    this.filter = _.extend(this.filter, { [key]: e })
    // @ts-ignore
    this.onFilterChange(checkAllGroup)
  }

  // @Watch('filter', { deep: true, immediate: true })
  private onFilterChange(checkAllProductGroup = false) {
    const groupsIdx = _.findIndex(this.filterTemplate, ["key", "product_group"])
    const groups = this.filterTemplate[groupsIdx].data
    // @ts-ignore
    if (this.filter.product_group?.length === groups.length) return
    // eslint-disable-next-line
    // @ts-ignore
    if (checkAllProductGroup) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.filter.product_group = _.map(groups, (f) => f.id)
    }

    localStorage.setItem("productFilter", qs.stringify(this.filter))
    const stringifiedFilter = _.reduce(
      this.filter,
      // @ts-ignore
      (acc, v, k) => _.extend(acc, { [k]: v?.join(",") }),
      ""
    )
    this.$emit("filter", stringifiedFilter)
  }

  private get drawerWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return 641
    if (bp.mdAndUp) return 433
    return 321
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private mounted() {
    this.fetchData()
  }

  private fetchData() {
    return ProductApi.listCategoryFilter().then(({ data }) => {
      this.filterTemplate = _.map(data, (d) => deserialize<ProductCategoryFilter>(d, ProductCategoryFilter))
      if (localStorage.getItem("productFilter")) {
        // @ts-ignore
        // eslint-disable-next-line
          this.filter = qs.parse(localStorage.getItem('productFilter')!);
        _.keys(this.filter).forEach((k: string) => {
          // @ts-ignore
          this.filter[k] = _.map(this.filter[k], (v) => parseInt(v, 10))
        })
        // console.log(this.filter);
      } else {
        this.filter = _.reduce(_.map(this.filterTemplate, "key"), (acc, v) => _.extend(acc, { [v]: [] }), {})
        // @ts-ignore
        // eslint-disable-next-line
          this.filter['product_group'] = parseInt(this.$route.params.id || '0', 10);
      }
    })
  }
}
